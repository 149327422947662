import React from "react";
import TabType from "@Tabs/types/Tab.type";
import {BannerPropsType} from "@SearchResults/features/banners/features/getByTransferBanner/types/BannerProps.type"
import {
    GetByFerryDataType
} from "@SearchResults/features/banners/features/getByTransferBanner/types/GetByFerryData.type"
import useGbtBanner from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGbtBanner";
import GetByTransferSearchResultsBanner
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferSearchResultsBanner";
import useExperiments from "@Hooks/selector/useExperiments";

type TopBannerProps = BannerPropsType & {
    getByFerryData: GetByFerryDataType,
    activeTab: TabType,
    numberOfResults: number,
}

function TopBanners(props: TopBannerProps) {
    const {
        hasActiveRoutes,
        transfersData,
        params,
        getByFerryData,
        activeTab,
        numberOfResults,
    } = props

    const {
        gbtBannerPosition,
        gbtBannerType,
        gbtShowBanner,
        gbtShowNoDirectRoutesNotification,
    } = useGbtBanner({transfersData, getByFerryData, numberOfResults})

    const {gbtBannerTopOfSearchResult} = useExperiments();

    return (
        <>
            {((hasActiveRoutes && gbtBannerPosition === 'top' && gbtShowBanner) && gbtBannerTopOfSearchResult) && (
                <GetByTransferSearchResultsBanner
                    transfersData={transfersData}
                    bannerType={gbtBannerType}
                    showNoResultsNotification={gbtShowNoDirectRoutesNotification}
                />
            )}
        </>
    );
}

export default TopBanners;
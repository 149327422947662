import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useBuyTicket from "@Route/features/ticketPurchase/hooks/useBuyTicket";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import ParamsType from "@SearchResults/types/params/Params.type";
import BuyTicketLabel from "@Route/features/ticketPurchase/components/BuyTicketLabel";

interface BuyButtonProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    params: ParamsType
}

function BuyButton({route, params}: BuyButtonProps) {
    const {
        allotment: {
            totalAllotmentLeft,
        },
        status: {
            isSuggestion,
        },
    } = route

    const isMobile = useIsMobile()
    const buyTicketTranslation = useTranslation("search_results.buy_ticket")
    // @ts-ignore
    const passengers = useAppSelector((state) => state?.form.passengers)
    const {passengers: totalPassenger} = passengers
    const buttonCompact = isSuggestion || buyTicketTranslation.length >= 15 ? 'btn--compact' : ''
    const suggestionClass = isSuggestion ? 'btn--suggestion' : ''
    const {buyTicket, loading, routeDisabled} = useBuyTicket(route, params)
    const className = (totalAllotmentLeft < totalPassenger) || routeDisabled ? 'btn--disabled' : 'btn--blue'

    return (
        <button
            disabled={routeDisabled}
            type="submit"
            style={loading ? {paddingTop: "7px", paddingBottom: "7px"} : {}}
            className={`btn ${className} ${buttonCompact} ${suggestionClass} ${isMobile ? 'btn--mobile' : ''} routes-list__cta routes-list__cta--operator`}
            onClick={buyTicket}
        >
            {loading ? (
                <LoaderButton/>
            ) : (
                <BuyTicketLabel
                    route={route}
                />
            )}
        </button>
    );
}

export default BuyButton
export const travelierConnect = 23
export const jadrolinija = 445
export const kriloShipping = 1133
export const tpLine = 444
export const kriloKapetanLuka = 446
export const specialBookingFeeCompanies = [
    jadrolinija,
    kriloShipping,
    tpLine,
    kriloKapetanLuka
]
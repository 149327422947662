import {setPassengerDataUpdating} from "@CheckOrder/reducers/checkOrderSlice";
import useSubmitPassengers from "@CheckOrder/hooks/useSubmitPassengers";
import useHandleError from "@Errors/hooks/useHandleError";
import {useCancelToken} from "@Hooks/promises/useCancelToken";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerType from "@CheckOrder/types/Passenger.type";
import isBefore from "date-fns/isBefore";
import parseDate from "@DateTime/functions/parseDate";
import {isAfter} from "date-fns";
import useGetAdditionalPassengerFieldByName from "@CheckOrder/hooks/useGetAdditionalPassengerFieldByName";
import {isValidName} from "@String/isValidName";

export default function useUpdatePassengers() {
    const dispatch = useAppDispatch()
    const {
        passengers: allPassengers,
        ticket: {
            requiredPassengerFields,
            ticketCode,
            additionalInfoFields,
            isReturn,
        },
        email,
        phoneNumber,
        phoneCountryObject,
        phoneValidated,
        passwordValidated,
        emailValidated,
    } = useAppSelector((state) => state?.checkOrder)

    const {
        getAdditionalPassengerFieldByName,
    } = useGetAdditionalPassengerFieldByName()

    // Map only passengers of "regular" type, skip addons such as vehicles
    const passengers = allPassengers.filter((passenger) => passenger.type === 'Passenger')

    const checkAdditionalFieldRequirements = (passenger: PassengerType, isReturn: boolean) => {
        const fieldsToCheck = isReturn ? additionalInfoFields.returnAdditionalFields : additionalInfoFields.singleAdditionalFields
        const passengerFieldsToCompare = isReturn ? passenger.returnAdditionalInfo : passenger.additionalInfo

        const allFieldsAdded = fieldsToCheck.additionalPassengerFields.every((field) => {
            return Object.keys(passengerFieldsToCompare).includes(field.key)
        })

        const allTicketFieldsAdded = fieldsToCheck.additionalTicketFields.every((field) => {
            return Object.keys(passengerFieldsToCompare).includes(field.key)
        })

        const allFieldsInput = Object.entries(passenger.additionalInfo).every(([fieldName, fieldValue]) => {
            const ticketField = getAdditionalPassengerFieldByName(fieldName)
            if (!fieldValue) {
                return false
            }

            if (ticketField.type === "text") {
                return (
                    fieldValue.length >= ticketField.minLength
                    && fieldValue.length <= ticketField.maxLength
                    && (ticketField?.validationRegex ? new RegExp(ticketField.validationRegex).test(fieldValue) : true))
            }

            if (ticketField.type === "number") {
                return (
                    fieldValue <= ticketField.max
                    && fieldValue >= ticketField.min
                )
            }

            if (ticketField.type === "date") {
                return (
                    isBefore(parseDate(fieldValue), parseDate(ticketField.max.date))
                    && isAfter(parseDate(fieldValue), parseDate(ticketField.min.date))
                )
            }

            return true
        })

        return allFieldsAdded && allTicketFieldsAdded && allFieldsInput
    }

    const checkAdditionalFields = (passenger: PassengerType) => {
        const singleValid = checkAdditionalFieldRequirements(passenger, false)
        if (isReturn) {
            return singleValid && checkAdditionalFieldRequirements(passenger, true)
        }

        return singleValid
    }

    const passengerFieldsSubmitted = () => (
        passengers.map((passenger) => {
            const {
                passengerFirstName,
                passengerLastName,
            } = passenger;
            return (
                !!passengerFirstName
                && isValidName(passengerFirstName)
                && !!passengerLastName
                && isValidName(passengerLastName)
                && checkAdditionalFields(passenger)
            )
        })
    )

    const {setError} = useHandleError()
    const {
        cancelToken,
        cancelRequest,
    } = useCancelToken({
        cancelMessage: 'error adding passenger',
        cancelOnNavigation: false,
        cancelOnUnload: false,
    })

    const handleError = async (error) => {
        cancelRequest()
        dispatch(setPassengerDataUpdating(false))
        await setError({
            error,
            additionalInfo: ticketCode,
        })
    }

    const {submitPassengers} = useSubmitPassengers(handleError, cancelToken)

    const checkAllPassengerFieldsSubmitted = () => (
        passengerFieldsSubmitted().every((i) => !!i)
    )

    // @ts-ignore
    const updateAllPassengers = async () => submitPassengers(passengers)

    const updateAllPassengersAndVerifySubmission = () => {
        return updateAllPassengers().then((response) => {
            return Promise.all(response.flat(Infinity).map((promise) => {
                return promise === 'fullfilled'
            }))
        })
    }

    const checkUserFieldValidation = () => (
        checkAllPassengerFieldsSubmitted()
        && email
        && phoneNumber
        && phoneCountryObject.dialCode
        && phoneValidated
        && passwordValidated
        && emailValidated
    )

    return {
        checkAllPassengerFieldsSubmitted,
        updateAllPassengers,
        updateAllPassengersAndVerifySubmission,
        checkUserFieldValidation,
    }
}
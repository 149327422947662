import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import PassengerInputField from "@CheckOrder/components/MainSection/PassengersInfo/PassengerInputField";
import {isValidName} from "@String/isValidName";

interface FirstNameProps {
    value: string,
    userConfirmed: boolean,
    index: number,
}

const FirstName = ({value, userConfirmed, index}: FirstNameProps) => (
    <PassengerInputField
        name="passengerFirstName"
        labelText={useTranslation("check_order.first_name")}
        value={value}
        showErrorText={(!value && userConfirmed) || (userConfirmed && !isValidName(value))}
        index={index}
    />
);

export default FirstName;
import {useState} from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useHandleError from "@Errors/hooks/useHandleError";
import useActivatePopup from "@Popups/hooks/useActivatePopup";
import NoTicketCodeError from "@Route/features/ticketPurchase/models/NoTicketCodeError";
import useRedirectToCheckOrder from "@Route/features/ticketPurchase/hooks/useRedirectToCheckOrder";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import checkSaleBlockTimeAndAllotmentLeft
    from "@Route/features/ticketPurchase/functions/checkSaleBlockTimeAndAllotmentLeft";
import PastSaleBlockTimeError from "@Route/features/ticketPurchase/models/PastSaleBlockTimeError";
import AllotmentError from "@Route/features/ticketPurchase/models/AllotmentError";
import {AllotmentErrorType} from "@Route/features/ticketPurchase/types/AllotmentError.type";
import usePassengers from "@Hooks/selector/usePassengers";
import ParamsType from "@SearchResults/types/params/Params.type";
import useSingleSelectedRouteResolvedFromParams from "@Route/hooks/useSingleSelectedRouteResolvedFromParams";

export default function useBuyTicket(route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType, params: ParamsType) {
    const {
        status: {
            isSuggestion,
        },
    } = route

    const {experiments} = useAppSelector((state) => state.page)
    const singleSelectedRoute = useSingleSelectedRouteResolvedFromParams(false)
    const passengers = usePassengers()
    const {
        date,
        returnDate,
        isReturn,
    } = params

    const {redirectToCheckOrder} = useRedirectToCheckOrder()
    const [loading, setLoading] = useState(false)
    const soldOut = useTranslation("search_results.no_more_tickets")
    const soldOutTitle = useTranslation("sys.sold_out")
    const genericError = useTranslation("sys.there_has_been_an_error")
    const {setError} = useHandleError()
    const {activatePopup} = useActivatePopup()
    const allotmentTitle = useTranslation("succ.important_notice")
    const allotmentNotice = useTranslation("sys.tickets_alottment_sold", {
        totalAllotmentLeft: route.allotment.totalAllotmentLeft,
        totalPassenger: passengers.passengers,
    })
    const [routeDisabled, setRouteDisabled] = useState(false)

    const activateAllotmentPopup = (error: AllotmentErrorType) => {
        activatePopup({
            title: error.title,
            message: error.message,
        })
    }

    const activateSoldOutPopup = () => {
        activatePopup({
            title: soldOutTitle,
            message: soldOut,
        })
    }

    const activateGenericErrorPopup = (error) => {
        setError({error, additionalInfo: route})
        activatePopup({
            title: "Error",
            message: genericError,
        })
        setLoading(false)
    }

    async function buyTicket(e) {
        e.stopPropagation()
        if (route.allotment.totalAllotmentLeft < passengers.passengers) {
            return activateAllotmentPopup({
                code: 500,
                message: allotmentNotice,
                title: allotmentTitle,
                parameters: {
                    seatsLeft: route.allotment.totalAllotmentLeft,
                },
            })
        }
        setLoading(true)

        try {
            const response = await checkSaleBlockTimeAndAllotmentLeft({
                route,
                singleSelectedRoute,
                isReturnSearch: isReturn,
                date: isSuggestion ? route.travelInfo.departureStation.departureDate : date,
                returnDate,
                passengers,
                experiments,
            })

            setLoading(false)

            if (response instanceof AllotmentError) {
                return activateAllotmentPopup(response.allotmentError)
            }
            if (response instanceof PastSaleBlockTimeError) {
                return activateSoldOutPopup()
            }

            if (response instanceof NoTicketCodeError) {
                setRouteDisabled(true)
                return activateGenericErrorPopup(`No ticket code generated for route ${route.id}`)
            }

            redirectToCheckOrder(response, isSuggestion)
        } catch (error) {
            setLoading(false)
            activateGenericErrorPopup(error)
            setRouteDisabled(true)
        }
    }

    return {
        buyTicket,
        loading,
        routeDisabled,
    }
}
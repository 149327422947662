import React from "react";
import GetByTransferNoResultsNotification
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferNoResultsNotification";
import GbtVehicles from "@Features/getByTransfer/components/GbtVehicles";
import NoResultsNotice from "@SearchResults/components/NoResults/NoResultsNotice";
import SearchResultsUrlsType from "@SearchResults/types/urls/SearchResultsUrls.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import GetByTransferBanner
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferBanner";
import {
    defaultTransfersData
} from "@SearchResults/features/banners/features/getByTransferBanner/constants/defaultTransfersData";

interface NoResultsWithGbtPromoProps {
    bannerType: false | "vehicle-snippets" | "banner",
    urls: SearchResultsUrlsType,
    params: ParamsType,
    transfersData: TransfersDataType,
    showNoDirectRoutesNotification: boolean,
}

function NoResultsWithGbtPromo(props: NoResultsWithGbtPromoProps) {
    const {
        bannerType,
        urls,
        params,
        transfersData,
        showNoDirectRoutesNotification,
    } = props

    const {
        fromName,
        toName,
        vehicles,
    } = transfersData || {}

    if (bannerType === "vehicle-snippets") {
        return (
            <>
                {showNoDirectRoutesNotification && (
                    <GetByTransferNoResultsNotification
                        fromName={fromName}
                        toName={toName}
                    />
                )}
                <GbtVehicles vehicles={vehicles}/>
                <NoResultsNotice
                    params={params}
                    urls={urls}
                    smallTitle
                />
            </>
        )
    }
    return (
        <>
            {showNoDirectRoutesNotification && (
                <GetByTransferNoResultsNotification
                    fromName={fromName}
                    toName={toName}
                />
            )}
            <GetByTransferBanner
                transfersData={transfersData ?? defaultTransfersData}
            />
            <NoResultsNotice
                urls={urls}
                params={params}
                smallTitle
            />
        </>
    )
}

export default NoResultsWithGbtPromo
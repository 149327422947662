import sum from "lodash/sum";
import PassengerGroupWithPricesAndCountType from "@SearchResults/types/passenger/PassengerGroupWithPricesAndCount.type";
import {isEmpty} from "@Array/isEmpty"
import {defaultBookingFee, specialBookingFee} from "@/constants/BookingFee";
import {specialBookingFeeCompanies, travelierConnect} from "@/constants/ApiProviders";

/**
 * Sums totalPrice for provided route pricePerGroup array
 * In cases where entire vehicle is booked, single passenger price is returned (as that price is for the whole vehicle)
 */
export default function generateRouteTotalPrice(pricesPerGroup: PassengerGroupWithPricesAndCountType[], isPrivateVehicle = false) {

    const totalPrice = sum(pricesPerGroup.map(({numberOfPassengers, priceWithDiscount}) => (
        numberOfPassengers * priceWithDiscount
    )))

    const generatePriceForPrivateVehicle = () => {
        if (!isEmpty(pricesPerGroup)) {
            return pricesPerGroup[0].priceWithDiscount
        }
        return 0;
    }

    return {
        pricesPerGroup,
        totalPrice: isPrivateVehicle ? generatePriceForPrivateVehicle() : totalPrice,
    }
}